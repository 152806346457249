import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from './auth.utils';
import { UserService } from '../user/user.service';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { api } from 'app/core/config/api.config';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated: boolean = false;
  private _httpClient = inject(HttpClient);
  private _userService = inject(UserService);
  private _cookieService = inject(CookieService);
  private _code: BehaviorSubject<any | null> = new BehaviorSubject(null);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set authenticated(value: boolean) {
    this._authenticated = value;
  }

  /**
   * Token split
   *
   */
  splitToken(token: string): string[] {
    const maxLength: number = 1000;
    const result: string[] = [];

    for (let i = 0; i < token.length; i += maxLength) {
      result.push(token.substring(i, i + maxLength));
    }

    return result;
  }

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    // console.log(new Blob([token]).size, '😀');
    const tokenParts: string[] = this.splitToken(token);
    const part: number = tokenParts.length;
    this._cookieService.set('x-access-len', part.toString());
    tokenParts.map((item, index) => {
      this._cookieService.set(`x-access-${index}`, item);
    });
  }

  get accessToken(): string {
    const part: number = Number(this._cookieService.get('x-access-len'));
    let token: string = '';
    for (let i = 0; i < part; i++) {
      token += this._cookieService.get(`x-access-${i}`);
    }
    return token;
  }

  get refreshToken(): string {
    const part: number = Number(this._cookieService.get('x-refresh-len'));
    let token: string = '';
    for (let i = 0; i < part; i++) {
      token += this._cookieService.get(`x-refresh-${i}`);
    }
    return token;
  }

  set refreshToken(token: string) {
    const tokenParts: string[] = this.splitToken(token);
    const part: number = tokenParts.length;
    this._cookieService.set('x-refresh-len', part.toString());
    tokenParts.map((item, index) => {
      this._cookieService.set(`x-refresh-${index}`, item);
    });
  }

  /**
   * Getter for code
   */
  get code$(): Observable<any> {
    return this._code.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    const headerDict = {
      subPath: 'reset-password',
    };

    const headers = new HttpHeaders(headerDict);

    return this._httpClient.post(api.forgotPassword, { email }, { headers });
  }

  /**
   * Reset password
   *
   * @param data
   */
  resetPassword(data: any): Observable<any> {
    return this._httpClient.post(api.resetPassword, data);
  }

  /**
   * Reset password validation code
   *
   * @param code
   */
  resetPasswordValidationCode(code: string | null): Observable<any> {
    return this._httpClient.post(api.validationCode, { code }).pipe(
      tap((response) => {
        this._code.next(response);
      }),
    );
  }

  /**
   * Confirmation code
   *
   * @param code
   */
  confirmationCode(code: string | null): Observable<any> {
    return this._httpClient.post(api.confirmationCode, { code }).pipe(
      tap((response) => {
        this._code.next(response);
      }),
    );
  }

  /**
   * Change password
   *
   * @param userId
   * @param currentPassword
   * @param newPassword
   */
  changePassword(
    userId: string,
    currentPassword: string,
    newPassword: string,
  ): Observable<any> {
    return this._httpClient
      .post(api.changePassword, {
        user_id: userId,
        current_password: currentPassword,
        new_password: newPassword,
      })
      .pipe(switchMap((response: any) => of(response)));
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(api.signIn, credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the cookie
        this.accessToken = response.access_token;
        this.refreshToken = response.refresh_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient.post(api.refreshToken, {
      token: this.refreshToken,
    });
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    this._cookieService.delete('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    const expired = AuthUtils.isTokenExpired(this.accessToken);
    if (expired) {
      return of(false);
    }

    // If the access token exists, and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
